import React, { useEffect, useState } from 'react'
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CheckoutAside from './checkout/CheckoutAside';
import { useResponsive } from '@farfetch/react-context-responsive';
import { useShoppingCart } from '../../hooks/useShoppingCart';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Button } from 'reactstrap';
import { useContext } from 'react';
import AppContext from '../../context/Context';
import { paypalPlaceOrder } from '../../helpers/rest';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const JKPayPalConfirmation = () => {
  const { greaterThan } = useResponsive();
  const { cartTotal: payableTotal, loading: cartLoading, getCartItems, shoppingCart } = useShoppingCart();
  const { currency } = useContext(AppContext);
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);


  useEffect(() => {
    getCartItems()
  }, [])

  const placeOrder = () => {
    setSubmitting(true);
    paypalPlaceOrder()
      .then(res => {
        console.log(res);
        toast.success('PayPal Order Placed');
        history.push('/my-jamtracks');
      })
      .catch(err => {
        if(err.status === 404){
          toast.error('PayPal authorization has expired. Please restart the PayPal confirmation process.');
        }else if(err.status === 422){
          toast.error(`PayPal Purchase Error: ${err.message}`);
        }else {
          toast.error('PayPal Error. please contact support@jamkazam.com');
        }
      }).finally(() => {
        setSubmitting(false);
      });
  }

  const cancelOrder = () => {
    history.push('/jamtracks');
  }

  return (
    <div style={{ width: greaterThan.sm ? '75%' : '100%' }} className="mx-auto">
      <ContentWithAsideLayout
        aside={cartLoading ? <div>Cart Loading...</div> : <CheckoutAside shoppingCart={shoppingCart} />}
        isStickyAside={false}
      >
        <Card className="mb-3">
          <FalconCardHeader title="Confirm your PayPal Payment" titleTag="h5" />
          <CardBody>
            <p>You have not yet made a payment via Paypal. Please review your purchase and confirm or cancel.</p>
            {cartLoading ? <div>Loading...</div> :
              <div>
                <h5 className='my-3'>Payable Total: {currency}{payableTotal}</h5>
                <Button color="primary" className='fs--1 me-2 mr-2' onClick={placeOrder}>
                  {submitting ? 'Processing...' : 'Confirm Payment' }
                </Button>
                <Button outline
                  color="secondary"
                  disabled={submitting}
                  className="fs--1" onClick={cancelOrder}>Cancel Payment</Button>
              </div>
            }

          </CardBody>
        </Card>
      </ContentWithAsideLayout>
    </div>
  )
}

export default JKPayPalConfirmation